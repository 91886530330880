import { EventSourcePolyfill } from "event-source-polyfill";
import AdminApi from "./admin-api";
let eventSource = null;

const validateUser = () => {
  AdminApi.validateToken().then((res) => {
    var isValid = res.response;
    if (!isValid) {
      localStorage.clear();
      window.location.hash = "";
      window.location.reload();
    }
  });
};

export const AuthListner = {
  connect: (url, token) => {
    if (eventSource != null) {
      eventSource.disconnect();
      eventSource = null;
    }
    eventSource = new EventSourcePolyfill(`${url}/auth/subscribe`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    eventSource.onmessage = (ev) => {
      var data = JSON.parse(ev.data);
      switch (data.eventType) {
        case "user:refresh":
          let id = data.data;
          if (id === AdminApi.getCurrentUser().Id) {
            validateUser();
          }
          AdminApi.updateUserCache(id);
          break;
        case "user:auth_change":
          validateUser();
          break;
        case "user:settings_sync":
          AdminApi.getUserSettings(null, true).then((res) => {}).catch((err) => {});
          break;
        default:
          break;
      }
    };
  },
  disconnect: () => {
    if (eventSource != null) {
      eventSource.disconnect();
      eventSource = null;
    }
  },
};
